<template>
  <div>
    <Navigation nameColor="rgba(51, 51, 51, 1)" />
    <div class="ai_bg_box">
      <div class="nav_box">
        <div class="null_box"></div>

        <img src="@/assets/ai/title.png" alt="">
        <el-tabs style="margin: 2rem 0;" v-loading.fullscreen.lock="fullscreenLoading" @tab-click="handleClick">
          <el-tab-pane :label="item" v-for="(item, index) in navList" :key="index"></el-tab-pane>
        </el-tabs>
        <!-- 二级菜单 -->
        <!-- 全部 -->
        <ul class="flex list" v-show="tabShow == '全部'">
          <li class="flex" @click="routePush(item.id)" v-for="(item, index) in menuAll" :key="index">
            <img :src="item.imgUrl" alt="">
            {{ item.title }}
            <!-- <span id="free" v-show="item.free != ''"></span> -->
          </li>
        </ul>
        <!-- AI绘画 -->
        <ul class="flex list" v-show="tabShow == 'AI绘画'">
          <li class="flex" @click="routePush(item.id)" v-for="(item, index) in menu6" :key="index">
            <img :src="item.imgUrl" alt="">
            {{ item.title }}
            <!-- <p></p> -->
          </li>
        </ul>
        <!-- AI图画 -->
        <ul class="flex list" v-show="tabShow == 'AI文书'">
          <li class="flex" @click="routePush(item.id)" v-for="(item, index) in menu7" :key="index">
            <img :src="item.imgUrl" alt="">
            {{ item.title }}
            <!-- <p></p> -->
          </li>
        </ul>
      </div>
    </div>
    <footerBot />
  </div>
</template>

<script>
export default {
  name: '',
  created() { },
  mounted() { },
  data() {
    return {
      navList: [
        '全部',
        'AI绘画',
        'AI文书'
      ],

      menuAll: [
        {
          id: 13,
          imgUrl: require('@/assets/ai/13.png'),
          title: 'AI绘画',
        },
        {
          id: 15,
          imgUrl: require('@/assets/ai/15.png'),
          title: '黑白图像上色',
        },
        {
          id: 16,
          imgUrl: require('@/assets/ai/16.png'),
          title: '图片风格转换',
        },
        // {
        //   id: 17,
        //   imgUrl: require('@/assets/ai/17.png'),
        //   title: '人物头像动漫化',
        // },
        {
          id: 22,
          imgUrl: require('@/assets/ai/22.png'),
          title: '背景生成',
        },
        {
          id: 23,
          imgUrl: require('@/assets/ai/23.png'),
          title: '抠图',
        },
        // {
        //   id: 24,
        //   imgUrl: require('@/assets/ai/24.png'),
        //   title: 'AI视频',
        // },
        {
          id: 18,
          imgUrl: require('@/assets/ai/18.png'),
          title: '律师函',
        },
        // {
        //   id: 19,
        //   imgUrl: require('@/assets/ai/19.png'),
        //   title: '合同审查',
        // },
        {
          id: 20,
          imgUrl: require('@/assets/ai/20.png'),
          title: '版权侵权告知书',
        },
        {
          id: 21,
          imgUrl: require('@/assets/ai/21.png'),
          title: '起诉书',
        },
      ],
      // AI绘画
      menu6: [
        {
          id: 13,
          imgUrl: require('@/assets/ai/13.png'),
          title: 'AI绘画',
        },
        {
          id: 15,
          imgUrl: require('@/assets/ai/15.png'),
          title: '黑白图像上色',
        },
        {
          id: 16,
          imgUrl: require('@/assets/ai/16.png'),
          title: '图片风格转换',
        },
        // {
        //   id: 17,
        //   imgUrl: require('@/assets/ai/17.png'),
        //   title: '人物头像动漫化',
        // },
        // {
        //   id: 22,
        //   imgUrl: require('@/assets/ai/22.png'),
        //   title: '背景生成',
        // },
      ],
      // AI审查
      menu7: [
        {
          id: 18,
          imgUrl: require('@/assets/ai/18.png'),
          title: '律师函',
        },
        // {
        //   id: 19,
        //   imgUrl: require('@/assets/ai/19.png'),
        //   title: '合同审查',
        // },
        {
          id: 20,
          imgUrl: require('@/assets/ai/20.png'),
          title: '版权侵权告知书',
        },
        {
          id: 21,
          imgUrl: require('@/assets/ai/21.png'),
          title: '起诉书',
        },
      ],
      tabShow: '全部',
      fullscreenLoading: false,//加载
    }
  },
  methods: {
    handleClick(tab, event) {
      this.tabShow = tab._props.label
      this.fullscreenLoading = false;
    },
    routePush(id) {
      console.log(id);
      switch (id) {
        case 13:
          this.$router.push('/paint')
          break
        case 15:
          this.$router.push('/blackWhite')
          break
        case 16:
          this.$router.push('/transImg')
          break
          // case 17:
          //   this.$router.push('/cartoon')
          break;
        case 18:
          this.$router.push('/lawyer')
          break;
        case 20:
          this.$router.push('/tort')
          break;
        case 21:
          this.$router.push('/prosecute')
          break;
        case 22:
          this.$router.push('/image_matting')
          break;
        case 23:
          this.$router.push('/change_back')
          break;
        case 24:
          this.$router.push('/aiVideo')

        default:
          break;
      }
    }
  },
}
</script>

<style lang=scss scoped>
@import '@/scss/wordOp';
</style>